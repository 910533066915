import {
  blue,
  blueDark,
  green,
  greenDark,
  orange,
  orangeDark,
  red,
  redDark,
  slate,
  slateDark,
} from "@tamagui/colors"
import { radius, zIndex, space, size } from "@tamagui/themes"
import { createTokens } from "@tamagui/web"
import { themePaletteConstructor } from "./themes/themeBuilder"

/**
 * The color tokens from Tamagui come via the radix ui color scales.
 * Radix has the color palettes for both light and dark modes that are used at
 * the same color number but render an inverted color.
 *
 * @see https://www.radix-ui.com/colors/docs/palette-composition/scales
 * TODO: Would be nice to have a check here of the current theme and then use
 *       the appropriate color tokens in the colorTokens.
 */
export const color = {
  ...blue,
  ...slate,
  ...green,
  ...orange,
  ...red,
} as const

export const colorDark = {
  ...blueDark,
  ...slateDark,
  ...greenDark,
  ...orangeDark,
  ...redDark,
} as const

export const closedLoopColors = {
  blue: "#0077C6",
  gold: "#FFB81C",
  green: "#26D07C",
  orange: "#FF8F1C",
  purple: "#E95FE4",
  red: "#FF5348",
  lightBlue: "#62B5E5",
  darkBlue: "#004C80",
  lightGray: "#DAE1E6",
  coolGray: "#425D6E",
  darkGray: "#232D33",
} as const

export const closedLoop = themePaletteConstructor()
export const fluent = themePaletteConstructor("fluent")
export const material = themePaletteConstructor("material")

export const tokens = createTokens({
  color,
  colorDark,
  closedLoopColors,

  // TODO: Clean these up to be nicer to use
  closedLoopLight: closedLoop.light,
  closedLoopDark: closedLoop.dark,
  fluentLight: fluent.light,
  fluentDark: fluent.dark,
  materialLight: material.light,
  materialDark: material.dark,

  radius,
  zIndex,
  space: {
    xl: space["$9"],
    lg: space["$7"],
    base: space["$5"],
    sm: space["$3"],
    xs: space["$2"],
    xxs: space["$1.5"],
    mini: space["$1"],
    ...space,
  },
  size,
})
