import { merge } from "lodash"
import closedLoopPalettes from "./closedloop/closedloop-v1"
import materialPalettes from "./material/material"
import fluentPalettes from "./fluent/fluent"
import defaultPalettes from "./default/tokens"
// import { createTokens } from "@tamagui/web"

/**
 * Constructs a theme object based on the provided theme name. It adds the default palette
 * tokens as the base and then extends them with theme-specific palette tokens.
 *
 * @param {string} themeName - The name of the theme to construct (e.g., "material", "fluent", etc.).
 * @returns {object} A theme object containing the merged palettes for the specified theme.
 */
export const themePaletteConstructor = (themeName?: string) => {
  const basePalettes = merge({}, defaultPalettes, closedLoopPalettes)

  switch (themeName) {
    case "material":
      return merge({}, basePalettes, materialPalettes)
    case "fluent":
      return merge({}, basePalettes, fluentPalettes)
    default:
      return merge({}, basePalettes)
  }
}
