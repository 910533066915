import {
  blue,
  blueDark,
  green,
  greenDark,
  orange,
  orangeDark,
  red,
  redDark,
  slate,
  slateDark,
} from "@tamagui/colors"

/**
 * The color tokens from Tamagui come via the radix ui color scales.
 * Radix has the color palettes for both light and dark modes that are used at
 * the same color number but render an inverted color.
 *
 * @see https://www.radix-ui.com/colors/docs/palette-composition/scales
 * TODO: Would be nice to have a check here of the current theme and then use
 *       the appropriate color tokens in the colorTokens.
 */
export default {
  light: {
    ...blue,
    ...slate,
    ...green,
    ...orange,
    ...red,
  },
  dark: {
    ...blueDark,
    ...slateDark,
    ...greenDark,
    ...orangeDark,
    ...redDark,
  },
} as const
