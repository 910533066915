export default {
  light: {
    background: "#ffffff",
    backgroundPrimary: "#ebedee",
    backgroundSecondary: "#f9f9f9",
    backgroundDetail: "#f9f9f9",
    backgroundInput: "#f9f9f9",
    backgroundMessage: "#f9f9f9",
    backgroundSelected: "#dfdfdf",
  },
  dark: {
    background: "#212121",
    backgroundPrimary: "#212121",
    backgroundSecondary: "#171717",
    backgroundDetail: "#212121",
    backgroundInput: "#2f2f2f",
    backgroundMessage: "#2f2f2f",
    backgroundSelected: "#373737",
  },
}
