import { useCallback, useContext, useState } from "react"
import { StorageContext, StoredValue, StoredValueType } from "./StorageContext"
import { useIsomorphicLayoutEffect } from "tamagui"
import { isNil } from "lodash"

export const useStoredBoolean = (key: string, defaultValue?: boolean) =>
  useStoredValue<boolean>(key, "boolean", defaultValue)
export const useStoredNumber = (key: string, defaultValue?: number) =>
  useStoredValue<number>(key, "number", defaultValue)
export const useStoredString = (key: string, defaultValue?: string) =>
  useStoredValue<string>(key, "string", defaultValue)
export const useStoredBuffer = (key: string, defaultValue?: Uint8Array) =>
  useStoredValue<Uint8Array>(key, "buffer", defaultValue)

// NOTE: Don't import storage directly unless you have a specific reason.
// Instead, use the hooks provided above.
export const useStorage = () => useContext(StorageContext)

const useStoredValue = <T extends StoredValue>(
  key: string,
  dataType: StoredValueType,
  defaultValue?: T,
): [T | undefined, (val: T | undefined) => void] => {
  /**
   * This method is required as a workaround for the fact that the storage
   * context is not available during SSR. Even though we only use client side rendering in nextjs,
   * to do the initial render during `yarn export`, we need the pages to be renderable. Since local storage
   * is not available in node, we cannot use it on the initial render. To get around that, we have to use a
   * useLayoutEffect to get the initial value from the storage on web, though we can just use storage.get
   * on mobile.
   *
   * To keep from having to remember this behavior, there are some utility functions here which can be treated
   * like React.useState, but which read/write to mmkv on mobile and local storage on web instead of storing just in-memory.
   */
  const storage = useStorage()
  const [value, _setValue] = useState<T>()

  useIsomorphicLayoutEffect(() => {
    _setValue(getValue(storage, key, dataType, defaultValue))

    const listener = storage.addOnValueChangedListener((changedKey) => {
      if (changedKey === key) {
        _setValue(getValue(storage, key, dataType, defaultValue))
      }
    })

    return () => listener.remove()
  }, [storage, key, dataType, defaultValue])

  const setValue = useCallback(
    (value: T | undefined) => {
      if (isNil(value)) {
        storage.delete(key)
      } else {
        storage.set(key, value)
      }
    },
    [key, storage],
  )

  return [value, setValue]
}

function getValue<T>(
  storage: ReturnType<typeof useStorage>,
  key: string,
  dataType: StoredValueType,
  defaultValue?: StoredValue,
): T | undefined {
  switch (dataType) {
    case "boolean":
      return (storage.getBoolean(key) ?? defaultValue) as T
    case "number":
      return (storage.getNumber(key) ?? defaultValue) as T
    case "buffer":
      return (storage.getBuffer(key) ?? defaultValue) as T
    case "string":
    default:
      return (storage.getString(key) ?? defaultValue) as T
  }
}
