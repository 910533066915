const globalColors = {
  clBlue: "#0077C6",
  clGold: "#FFB81C",
  clGreen: "#26D07C",
  clOrange: "#FF8F1C",
  clPurple: "#E95FE4",
  clRed: "#FF5348",
  clLightBlue: "#62B5E5",
  clDarkBlue: "#004C80",
  clLightGray: "#DAE1E6",
  clCoolGray: "#425D6E",
  clDarkGray: "#232D33",
}

export default {
  light: { ...globalColors },
  dark: { ...globalColors },
} as const
