import { IconName } from "@fortawesome/fontawesome-svg-core"
import { Icon } from "@my/ui"
import { usePathname } from "app/hooks/usePathname"
import { trackButtonClicked } from "app/telemetry"
import { useAppStore } from "app/global-state/appStore"
import { forwardRef, useCallback, useEffect, useRef, useState } from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useRouter } from "solito/router"
import {
  isWeb,
  SizableText,
  Stack,
  StackProps,
  Tabs,
  TabsTabProps,
  useMedia,
  useWindowDimensions,
  XStack,
  YStack,
} from "tamagui"

export type TabProps = {
  value: string
  label: string
  iconName: IconName
}

interface TabBarProps extends StackProps {
  tabs: TabProps[]
}

export function TabBar({ tabs, children }: TabBarProps) {
  const { sm } = useMedia()
  const { height: windowHeight } = useWindowDimensions()

  return (
    <Tabs
      defaultValue={tabs[0]?.value}
      flex={1}
      flexDirection={sm ? "column" : "row"}
      height={windowHeight}
      maxHeight={windowHeight}
      orientation={sm ? "horizontal" : "vertical"}
    >
      {sm ?
        <BottomTabBar tabs={tabs}>{children}</BottomTabBar>
      : <LeftTabBar tabs={tabs}>{children}</LeftTabBar>}
    </Tabs>
  )
}

function LeftTabBar({ tabs, children }: TabBarProps) {
  return (
    <XStack overflow="hidden" style={{ height: "100vh", width: "100vw" }}>
      <YStack
        alignContent="center"
        alignItems="center"
        backgroundColor="$backgroundPrimary"
        borderRightColor="$borderContainer"
        borderRightWidth={1}
        gap="$xs"
        overflow="hidden"
      >
        <TabsList tabs={tabs} />
      </YStack>

      <TabsContents tabs={tabs}>{children}</TabsContents>
    </XStack>
  )
}

function BottomTabBar({ tabs, children }: TabBarProps) {
  const bottomNavRef = useRef<HTMLDivElement>(null)
  const { setBottomNavHeight } = useAppStore()

  useEffect(() => {
    if (bottomNavRef.current) {
      setBottomNavHeight(bottomNavRef.current.clientHeight)
    }
  }, [setBottomNavHeight, bottomNavRef.current?.clientHeight])

  return (
    <YStack flex={1} overflow="hidden">
      <TabsContents tabs={tabs}>{children}</TabsContents>
      <TabsList
        ref={bottomNavRef}
        tabs={tabs}
        backgroundColor="$backgroundSecondary"
        borderTopColor="$borderContainer"
        borderTopWidth={1}
      />
    </YStack>
  )
}

const TabsList = forwardRef<HTMLDivElement, TabBarProps>(({ tabs, ...props }, ref) => {
  const router = useRouter()
  const path = usePathname()
  const { sm } = useMedia()
  const { bottom } = isWeb ? { bottom: 0 } : useSafeAreaInsets()
  const [activeTab, setActiveTab] = useState(0)
  const [hoveringTab, setHoveringTab] = useState<number | null>(null)
  const tabGap = 46

  const handleTabClick = useCallback(
    (tabValue: string, tabLabel: string) => {
      router.replace(tabValue)
      trackButtonClicked(`Navigation:${tabLabel}`, "TabBar")
    },
    [router],
  )

  const handleMouseEnter = useCallback((index: number) => setHoveringTab(index), [])
  const handleMouseLeave = useCallback(() => setHoveringTab(null), [])

  useEffect(() => {
    const currentTab = tabs.findIndex((tab) => path?.startsWith(tab.value))

    if (currentTab !== activeTab) {
      setActiveTab(currentTab)
    }
  }, [path, tabs, setActiveTab])

  return (
    <Stack flexDirection={sm ? "row" : "column"} ref={ref} {...props}>
      <Tabs.List
        $sm={{ paddingHorizontal: "$base" }}
        $gtSm={{ gap: tabGap, paddingTop: "$base" }}
        flex={1}
        borderRadius={0}
      >
        {tabs.map((tab, index) => {
          const isActive = activeTab === index
          const isHovering = hoveringTab === index

          const colorToken =
            isActive ? "$textSelected"
            : isHovering ? "$text"
            : "$textSecondary"
          const iconColorToken =
            isActive ? "$textSelected"
            : isHovering ? "$text"
            : "$iconSecondary"
          const backgroundColorToken =
            isActive ? "$backgroundSelected"
            : isHovering ? undefined
            : "$backgroundPrimary"

          return (
            <Tabs.Tab
              key={tab.value}
              {...getTabProps(tab.value, sm, bottom)}
              backgroundColor={isWeb ? backgroundColorToken : undefined}
              $sm={{ borderRadius: 0 }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onPress={() => handleTabClick(tab.value, tab.label)}
              testID={`tab-bar-${tab.label.toLowerCase()}-button`}
            >
              <Icon
                active={isActive}
                color={iconColorToken}
                hovering={isHovering}
                icon={tab.iconName}
              />
              <SizableText size="$5" marginTop="$mini" color={colorToken}>
                {tab.label}
              </SizableText>
            </Tabs.Tab>
          )
        })}
      </Tabs.List>
    </Stack>
  )
})

function getTabProps(value: string, isSmall: boolean, bottomInset: number): TabsTabProps {
  return {
    alignItems: "center",
    borderRadius: "$5",
    flex: isSmall ? 1 : 0,
    flexDirection: "column",
    marginHorizontal: "$sm",
    paddingTop: "$sm",
    paddingBottom: bottomInset ? 0 : "$sm",
    unstyled: true,
    value,
    width: isSmall ? undefined : "$8",
  }
}

function TabsContents({ tabs, children }: TabBarProps) {
  return (
    <>
      {tabs.map((tab) => (
        <Tabs.Content flex={1} key={tab.value} value={tab.value}>
          {children}
        </Tabs.Content>
      ))}
    </>
  )
}
