import { changeCase } from "@my/utils/src/stringUtils/changeCase"
import type { CheckboxProps } from "tamagui"
import { Checkbox, XStack } from "tamagui"
import { Icon, IconSize } from "./icon/Icon"

export function CheckboxWithLabel({
  size = "$4",
  label,
  textId = "",
  ...checkboxProps
}: CheckboxProps & { size?: string; label?: React.ReactNode; textId: string }) {
  const id = `checkbox-${changeCase.dashCase(textId)}`
  return (
    <XStack paddingHorizontal="$xs" gap="$sm" alignItems="center">
      <Checkbox
        id={id}
        size={size}
        borderColor="$textSecondary"
        focusStyle={{ borderColor: "$textSecondary" }}
        hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
        {...checkboxProps}
      >
        <Checkbox.Indicator>
          <Icon icon="check" size={IconSize.Small} />
        </Checkbox.Indicator>
      </Checkbox>
      {label}
    </XStack>
  )
}
