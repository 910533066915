import { Environment } from "@my/config/src/environment"

export type Callback = (details: any) => void
export type Socket = {
  init(tokenFn: () => Promise<string | undefined>): void
  setTokenFn(tokenFn: () => Promise<string | undefined>): void
  close(): void
  reconnect({ force }: { force: boolean }): Promise<void>
  on(keys: string | string[], callback: Callback): void
  emit(data: Record<string, string | number | boolean | null | undefined> | string): void
}

export class SocketImpl implements Socket {
  private client: WebSocket | undefined
  private callbacks: Record<string, Callback> = {}
  private connectionKeeper: NodeJS.Timeout | undefined
  private tokenFn: (() => Promise<string | undefined>) | undefined

  getStatus() {
    return {
      client: this.client,
      callbacks: this.callbacks,
      keeper: this.connectionKeeper,
    }
  }

  init(tokenFn: () => Promise<string | undefined>) {
    this.setTokenFn(tokenFn)
    this.close()
    this.createClient().then(() => {
      this.monitorSocket()
    })
  }

  setTokenFn(tokenFn: () => Promise<string | undefined>) {
    this.tokenFn = tokenFn
  }

  private async createClient() {
    this.client = new WebSocket(`${Environment.SERVER_WS_URL}/ws/${await this.tokenFn!()}`)
    this.client.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const callback = this.callbacks[data.type]
      callback?.(data)
    }
  }

  private monitorSocket() {
    if (!this.connectionKeeper) {
      this.connectionKeeper = setInterval(() => {
        this.reconnect()
      }, 10_000)
    }
  }

  close() {
    if (this.connectionKeeper) {
      clearInterval(this.connectionKeeper)
      this.connectionKeeper = undefined
    }
    if (this.client) {
      this.client.close()
      this.client = undefined
    }
  }

  reconnect({ force } = { force: false }): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.client) {
        resolve()
        return
      }
      if (force || this.client?.readyState !== WebSocket.OPEN) {
        this.close()
        this.createClient().then(() => {
          this.client!.onopen = () => resolve()
          this.monitorSocket()
        })
      } else {
        resolve()
      }
    })
  }

  on(keys: string | string[], callback: Callback) {
    if (typeof keys === "string") {
      this.callbacks[keys] = callback
    } else {
      keys.forEach((key) => {
        this.callbacks[key] = callback
      })
    }
  }

  emit(data: Record<string, string | number | boolean | null | undefined> | string) {
    this.client?.send(JSON.stringify(data))
  }
}
