import { useHttpClient } from "@my/api"
import { Button, CheckboxWithLabel, ShadowlessDialogContent } from "@my/ui"
import { useUserStore } from "app/global-state/userState"
import { useLogout } from "app/hooks/useLogout"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { useUtm } from "app/utm/useUtm"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ImageStyle } from "react-native"
import Markdown from "react-native-markdown-display"
import {
  Dialog,
  ScrollView,
  Text,
  useMedia,
  useTheme,
  View,
  VisuallyHidden,
  XStack,
  YStack,
} from "tamagui"
import { useStoredBoolean } from "../../../storage"
import { formatUtmForMixpanel } from "app/utm/formatUtmForMixpanel"

interface TermsAndConditions {
  consented: boolean
  setConsented: (consented: boolean) => void
}

export const TermsAndConditions: React.FC<TermsAndConditions> = ({ consented, setConsented }) => {
  const { user } = useUserStore()
  const { handleLogout } = useLogout()
  const { sm } = useMedia()
  const [loading, setLoading] = useState(true)
  const [termsOfService, setTermsOfService] = useState<TermsOfService>()
  const [acceptedVersionBefore, setAcceptedVersionBefore] = useStoredBoolean(
    "has-accepted-tos",
    false,
  )
  const [acceptTos, setAcceptTos] = useState(false)
  const [acceptPrivacyPolicies, setAcceptPrivacyPolicies] = useState(false)
  const httpClient = useHttpClient()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()

  mixpanel.trackOnMount(
    { page_name: "TermsAndConditions", ...formatUtmForMixpanel(utmParameters) },
    isUtmLoading,
  )

  const fetchTerms = useCallback(async () => {
    const tos = await httpClient.get<{ terms: TermsOfService }>(`/terms-of-service`)
    if (tos) {
      setLoading(false)
      setTermsOfService(tos?.terms)
    }
  }, [httpClient])

  const fetchConsent = useCallback(async () => {
    const userConsent = await httpClient.get<boolean>(`/terms-of-service/consented`)
    if (userConsent) {
      setLoading(false)
      setConsented(true)
    } else {
      fetchTerms()
    }
  }, [fetchTerms, httpClient])

  useEffect(() => {
    if (!user) return
    fetchConsent()
  }, [user, fetchConsent])

  const handleConsent = useCallback(async () => {
    const updateConsent = await httpClient.post(`/terms-of-service/${termsOfService!.id}/consent`)
    if (updateConsent) {
      setConsented(true)
      setAcceptedVersionBefore(true)
    }
  }, [httpClient, setAcceptedVersionBefore, setConsented, termsOfService])

  const theme = useTheme()

  const checkboxMarkdownStyle = useMemo(
    () => ({
      body: {
        color: theme?.text?.val,
      },
      link: {
        color: theme?.textAction?.val,
      },
    }),
    [theme],
  )

  const termsMarkdownStyle = useMemo(
    () => ({
      link: checkboxMarkdownStyle?.link,
      body: {
        lineHeight: 20,
        color: theme?.text?.val,
      },
      bullet_list: {
        paddingLeft: sm ? 20 : 40,
      },
      bullet_list_icon: {
        display: "none" as ImageStyle["display"],
      },
    }),
    [theme, sm],
  )

  return (
    <>
      {!loading && (
        <Dialog open={!consented}>
          <Dialog.Portal>
            <Dialog.Overlay key="overlay" />
            <ShadowlessDialogContent key="content" maxHeight="90%" maxWidth={1000}>
              <VisuallyHidden>
                <Dialog.Description />
              </VisuallyHidden>

              <View flex={1}>
                <Dialog.Title marginBottom="$sm">
                  {acceptedVersionBefore ?
                    `We've updated our Terms of Service`
                  : `Terms of Service`}
                </Dialog.Title>
                <View flex={1} alignItems="center">
                  <ScrollView width={sm ? "100%" : "90%"}>
                    <Markdown style={termsMarkdownStyle}>{termsOfService?.content ?? ""}</Markdown>
                    <YStack gap="$xs" marginTop="$base">
                      <CheckboxWithLabel
                        textId="acceptTos"
                        checked={acceptTos}
                        onCheckedChange={() => setAcceptTos((prev) => !prev)}
                        label={
                          <View flex={1} flexShrink={1} width={sm ? "95%" : "unset"}>
                            <Markdown style={checkboxMarkdownStyle}>
                              {`I have read and accept the [Terms of Service](https://www.closedloop.ai/hc-terms-of-use/).`}
                            </Markdown>
                          </View>
                        }
                      />
                      <CheckboxWithLabel
                        textId="acceptPPandMHMD"
                        checked={acceptPrivacyPolicies}
                        onCheckedChange={() => setAcceptPrivacyPolicies((prev) => !prev)}
                        label={
                          <View flex={1} flexShrink={1} width={sm ? "95%" : "unset"}>
                            <Markdown style={checkboxMarkdownStyle}>
                              {`I have read and accept the [Privacy Policy](https://www.closedloop.ai/consumer-privacy-policy/) and the [Consumer Health Data Privacy Policy](https://www.closedloop.ai/mhmd-act-consumer-health-privacy/).`}
                            </Markdown>
                          </View>
                        }
                      />
                    </YStack>
                    <YStack flex={1} gap="$xs">
                      {(!acceptTos || !acceptPrivacyPolicies) && (
                        <Text marginTop="$base" color="$textSecondary">
                          You must check both boxes before continuing.
                        </Text>
                      )}
                      <XStack justifyContent="flex-end">
                        <Button
                          variant="secondary"
                          margin="$sm"
                          onPress={() => {
                            handleLogout()
                            trackButtonClicked("Decline", "TermsAndConditions", {
                              tos_version: termsOfService?.version,
                            })
                          }}
                        >
                          Decline
                        </Button>
                        <Button
                          variant="primary"
                          margin="$sm"
                          disabled={!acceptTos || !acceptPrivacyPolicies}
                          onPress={() => {
                            handleConsent()
                            trackButtonClicked("Accept", "TermsAndConditions", {
                              tos_version: termsOfService?.version,
                            })
                          }}
                        >
                          Accept
                        </Button>
                      </XStack>
                    </YStack>
                  </ScrollView>
                </View>
              </View>
            </ShadowlessDialogContent>
          </Dialog.Portal>
        </Dialog>
      )}
    </>
  )
}
