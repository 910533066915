import { User, useAuth, useHttpClient } from "@my/api"
import { Button } from "@my/ui"
import { useNetInfoInstance } from "@react-native-community/netinfo"
import { HealthCoachLogo } from "app/assets/images/HealthCoachLogo"
import { useFeatureFlagClient } from "app/feature-flags"
import { useUserStore } from "app/global-state/userState"
import { useLogout } from "app/hooks/useLogout"
import { mixpanel, setMonitoringUser, trackButtonClicked, useDistinctId } from "app/telemetry"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Spinner,
  SizableText,
  Text,
  View,
  YStack,
  isWeb,
  useMedia,
  useWindowDimensions,
} from "tamagui"
import { LegalLinks } from "app/features/settings/LegalLinks"
import { useUtm } from "app/utm/useUtm"
import { formatUtmForMixpanel } from "app/utm/formatUtmForMixpanel"
import { useStoredBoolean } from "app/storage"
import { writeToDebug } from "@my/config/src/debug"

export function LoginFlow({ children }: { children: React.ReactNode }) {
  const { utmParameters, isLoading: isUtmLoading } = useUtm()
  const httpClient = useHttpClient()
  const ffClient = useFeatureFlagClient()
  const { handleLogout } = useLogout()
  const { isLoading, isAuthenticated, error } = useAuth()
  const { user, setUser } = useUserStore()
  const {
    netInfo: { isInternetReachable },
  } = useNetInfoInstance()
  const [waitlist, setWaitlist] = useState<boolean>(false)
  const [isGeoblocked, setIsGeoblocked] = useState<boolean>(false)
  const [showLogoutButton, setShowLogoutButton] = useState<boolean>(false)

  // When the user logs out on mobile, the app loses focus momentarily, which causes the privacy
  // view to be displayed and this component to be removed from the tree. When this component
  // is subsequently re-added, the use-effect blocks below run even though the state they are
  // dependent on has not changed. This workaround prevents this from happening.
  const [previousIsAuthenticated, setPreviousIsAuthenticated] = useState<boolean>(isAuthenticated)

  useEffect(() => {
    if (isLoading || !user) {
      const timer = setTimeout(() => {
        setShowLogoutButton(true)
      }, 5000)

      return () => clearTimeout(timer)
    } else {
      setShowLogoutButton(false)
    }
  }, [isLoading, user])

  useEffect(() => {
    if (isAuthenticated && !previousIsAuthenticated && httpClient.isInitialized && !isUtmLoading) {
      const forbiddenErrorHandler = () => {
        setWaitlist(true)
        return true
      }
      const geoblockedErrorHandler = () => {
        setIsGeoblocked(true)
        return true
      }
      httpClient
        .post<User>(
          "/users",
          { utmParameters },
          {
            apiVersion: 2,
            errorHandlers: {
              onForbidden: forbiddenErrorHandler,
              onGeoblocked: geoblockedErrorHandler,
            },
          },
        )
        .then((user) => {
          setUser(user)
        })
    }
    setPreviousIsAuthenticated(isAuthenticated)
  }, [isAuthenticated, httpClient, isUtmLoading])

  // Fetch the mixpanel distinctId
  const distinctId = useDistinctId()
  // Useful for debugging anonymous sessions in mixpanel
  writeToDebug(`distinctId(${distinctId})`)

  /*
   * Note: When a user logs in and then logs out, the distinctId will become the previous user's auth0Id.
   * We don't want to alias in this case because that would link the two users together. This ensures we
   * only call alias once per app install.
   */
  const [needsMergedAlias, setNeedsMergedAlias] = useStoredBoolean(
    "telemetry:needsMergedAlias",
    true,
  )

  /*
   * This boolean is used to determine if we need to identify the current user. It ensures we don't hammer both mixpanel
   * and LaunchDarkly with calls. It is set to true when the user logs out. We're only supposed to call identify once per
   * user signup or login.
   */
  const [needsIdentify, setNeedsIdentify] = useStoredBoolean("telemetry:needsIdentify", true)

  useEffect(() => {
    if (needsMergedAlias === true && user && distinctId && distinctId !== user.auth0Id) {
      writeToDebug(`Merging user identities. user(${user.auth0Id}) => anonymous(${distinctId})`)
      mixpanel.alias(user.auth0Id, distinctId)
      setNeedsMergedAlias(false)
    }
  }, [user, distinctId, needsMergedAlias])

  useEffect(() => {
    if (needsIdentify === true && user) {
      writeToDebug(`Identifying user in mixpanel. user(${user.auth0Id})`)
      setMonitoringUser(user)
      setNeedsIdentify(false)

      // Identify user in LaunchDarkly
      ffClient.identify(user.email)
    }
  }, [user, needsIdentify, ffClient])

  if (isInternetReachable !== false) {
    if (user) {
      return <>{children}</>
    } else if (error?.message?.includes("verification email")) {
      return <VerifyEmailScreen />
    } else if (!isLoading && waitlist) {
      return <WaitlistScreen setWaitlist={setWaitlist} />
    } else if (!isLoading && isGeoblocked) {
      return <LandingScreen isGeoblocked />
    } else if (!isLoading && !isAuthenticated) {
      return <GetStartedScreen />
    }
  }

  return (
    <LandingScreen isLoading>
      {showLogoutButton && (
        <Button
          variant="primary"
          onPress={() => {
            handleLogout()
          }}
        >
          Back to Login
        </Button>
      )}
    </LandingScreen>
  )
}

function GetStartedScreen() {
  return (
    <LandingScreen>
      <GetStarted />
    </LandingScreen>
  )
}

function VerifyEmailScreen() {
  return (
    <LandingScreen>
      <VerifyEmail />
    </LandingScreen>
  )
}

function WaitlistScreen({ setWaitlist }: { setWaitlist: (waitlist: boolean) => void }) {
  const httpClient = useHttpClient()
  const [hasJoinedWaitlist, setHasJoinedWaitlist] = useState<boolean>(false)
  const { logout } = useAuth()
  const { sm } = useMedia()

  const handleJoinWaitlist = useCallback(async () => {
    if (!hasJoinedWaitlist) {
      const response = await httpClient.post(`/private/system/request-access`)
      if (response) {
        setHasJoinedWaitlist(true)
      }
    }
  }, [hasJoinedWaitlist, httpClient])

  return (
    <LandingScreen>
      {hasJoinedWaitlist ?
        <Text textAlign="center">
          Thank you for joining the waitlist.{"\n"}
          We’ll send you an email when your invite is ready.
        </Text>
      : <Text textAlign="center" width={sm ? "85%" : "100%"}>
          Health Coach is currently available by invite only.{"\n"}
          If you would like to join the waitlist, please sign up below.
        </Text>
      }
      {!hasJoinedWaitlist && (
        <Button variant="primary" marginTop="$base" onPress={handleJoinWaitlist}>
          Join Waitlist
        </Button>
      )}
      <Button
        unstyled
        color="$textAction"
        marginTop="$base"
        onPress={() => {
          setWaitlist(false)
          logout()
        }}
      >
        Start Over
      </Button>
    </LandingScreen>
  )
}

function LandingScreen({
  children,
  isLoading = false,
  isGeoblocked = false,
}: {
  children?: React.ReactNode
  isLoading?: boolean
  isGeoblocked?: boolean
}) {
  const { height } = useWindowDimensions()
  const {
    netInfo: { isInternetReachable },
  } = useNetInfoInstance()
  const isOnline = isInternetReachable !== false

  return (
    <View flex={1} height={height} $gtSm={{ alignItems: "center" }} width="100%">
      <YStack
        flex={1}
        $gtSm={{
          width: 400,
        }}
        alignItems="center"
        justifyContent="center"
        padding="$base"
      >
        <View flex={1} />
        <HealthCoachLogo width={200} height={152} />
        {isOnline && !isGeoblocked && (
          <Text color="$text" paddingTop="$lg">
            Your Health Coach. Online 24/7.
          </Text>
        )}
        {isOnline && isGeoblocked && (
          <Text color="$text" paddingTop="$lg">
            Thank you for your interest in Health Coach. Unfortunately, Health Coach is only
            available in the United States.
          </Text>
        )}
        <View flex={1} />
        {children}
        <View flex={1} />
        {isLoading ?
          <>
            <Spinner size="large" color="$backgroundInfo" />
            <View height="$1" />
          </>
        : null}
        {isOnline ?
          <LegalLinks />
        : <>
            <SizableText color="$text" fontSize="$4" textAlign="center">
              You are offline.
            </SizableText>
            <View height="$0.75" />
            <SizableText color="$text" fontSize="$3" textAlign="center">
              Please check your connection.
            </SizableText>
          </>
        }
      </YStack>
    </View>
  )
}

function GetStarted() {
  const { login, signup } = useAuth()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()

  mixpanel.trackOnMount(
    { page_name: "LoginSelection", ...formatUtmForMixpanel(utmParameters) },
    isUtmLoading,
  )

  return (
    <>
      <Button
        variant="primary"
        width="100%"
        onPress={() => {
          signup()
          trackButtonClicked("SignUp", "LoginSelection", { ...formatUtmForMixpanel(utmParameters) })
        }}
        data-testid="login-flow-get-started"
      >
        Get Started
      </Button>
      <View height="$1" />
      <Button
        width="100%"
        variant="secondary"
        onPress={() => {
          login()
          trackButtonClicked("LogIn", "LoginSelection", { ...formatUtmForMixpanel(utmParameters) })
        }}
        data-testid="login-flow-login"
      >
        Log In
      </Button>
    </>
  )
}

function VerifyEmail() {
  const { login, logout, error } = useAuth()
  const infoText = useMemo(() => getErrorInfoText(error), [error])
  const { utmParameters, isLoading: isUtmLoading } = useUtm()

  mixpanel.trackOnMount(
    { page_name: "LoginVerification", ...formatUtmForMixpanel(utmParameters) },
    isUtmLoading,
  )

  return (
    <>
      <Text color="$text" fontWeight="700">
        Verify your account
      </Text>
      <View height="$0.75" />
      <Text color="$text" textAlign="center">
        {infoText}
      </Text>
      <View height="$3.5" />
      <Button
        width="100%"
        backgroundColor="$buttonPrimary"
        color="$textDark"
        hoverStyle={{
          backgroundColor: "$buttonPrimaryHover",
          borderColor: "$buttonPrimaryHover",
        }}
        onPress={() => {
          login()
          trackButtonClicked("LogIn", "LoginVerification", {
            ...formatUtmForMixpanel(utmParameters),
          })
        }}
      >
        Log In
      </Button>
      <Text
        color="$textLink"
        cursor="pointer"
        marginVertical="$3.5"
        onPress={() => {
          logout()
          trackButtonClicked("Cancel", "LoginVerification", {
            ...formatUtmForMixpanel(utmParameters),
          })
        }}
        textAlign="center"
      >
        Cancel
      </Text>
    </>
  )
}

function getErrorInfoText(error: Error | null): string | undefined {
  if (isWeb) {
    return error?.message
  }
  return error?.message.split("CAUSE:")[1]?.trim() || error?.message
}
