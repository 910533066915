/**
 * Global debug flag for the application.
 *
 * When set to true, it enables debug mode across the application,
 * allowing debug messages to be logged and potentially enabling
 * other debug-related features.
 *
 * This flag is used by functions like `writeToDebug` to determine
 * whether to output debug information.
 *
 * @type {boolean}
 * @default true
 */
// This enables debug mode when set to true
export const DEBUG: boolean = false

/**
 * Logs debug information to the console if DEBUG is set to true.
 *
 * This function is used for debugging purposes throughout the application.
 * It only logs the information when the DEBUG flag is set to true,
 * allowing for easy toggling of debug output.
 *
 * @param {...any[]} args - Any number of arguments to be logged.
 * @example
 * writeToDebug("User logged in", user);
 * writeToDebug("API response", response);
 */
export function writeToDebug(...args: any[]) {
  if (DEBUG) {
    console.debug(...args)
  }
}
