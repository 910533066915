import { closedLoop, material, fluent } from "./tokens"

export const themes = {
  light: closedLoop.light,
  dark: closedLoop.dark,
  light_closedLoop: closedLoop.light,
  dark_closedLoop: closedLoop.dark,
  light_material: material.light,
  dark_material: material.dark,
  light_fluent: fluent.light,
  dark_fluent: fluent.dark,
}
