import { create } from "./create"

export type Theme = "material" | "fluent" | "closedLoop"
export const Themes: Record<Theme, Theme> = {
  material: "material",
  fluent: "fluent",
  closedLoop: "closedLoop",
}

// TODO: Add support for system - need to be able to get the system color mode and set the value for light/dark mode
// Theme palettes will only have light and dark.
export type ColorMode = "light" | "dark" | "system"
export const ColorModes: Record<ColorMode, ColorMode> = {
  light: "light",
  dark: "dark",
  system: "system",
}

export const useTheme = create<{
  theme: Theme
  setTheme: (theme: Theme) => void
  colorMode: ColorMode
  setColorMode: (colorMode: ColorMode) => void
}>((set, get) => {
  const theme = get()?.theme || Themes.material
  const colorMode = get()?.colorMode || ColorModes.light

  const setTheme = (newTheme: Theme) => {
    const theme = newTheme || get()?.theme || Themes.closedLoop
    set({ theme })
  }

  const setColorMode = (newColorMode: ColorMode) => {
    const colorMode = newColorMode || get()?.colorMode || Themes.closedLoop
    set({ colorMode })
  }

  return {
    theme,
    setTheme,
    colorMode,
    setColorMode,
  }
})
